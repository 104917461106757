import styled from "styled-components";
import Img from "gatsby-image";

import { TextField } from "../../Contract/Form";

export const SearchContainer = styled.div`
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.h1`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #fce33f;
  position: relative;
  margin-top: 32px;
  margin-bottom: 10px;
`;

export const Description = styled.h2`
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  position: relative;
  margin-top: 0;
  margin-bottom: 34px;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  width: 100%;
`;

export const Background = styled(Img)`
  width: 100%;
  height: 100%;
`;

export const SearchField = styled(TextField)`
  background-color: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
  width: 555px;
  @media (max-width: 768px) {
    width: 443px;
  }
  @media (max-width: 767px) {
    max-width: 345px;
  }
  .MuiInputBase-input {
    width: 87%;
    @media (max-width: 767px) {
      width: 80%;
    }
  }
`;

export const SearchButton = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  right: 19px;
  cursor: pointer;
`;
