import React, { ReactElement } from "react";

interface SearchProps {
  fill: string;
}

const Search = ({ fill }: SearchProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M22.115 20.361l-5.75-5.752c1.03-1.405 1.583-3.105 1.575-4.848-.02-4.55-3.7-8.235-8.25-8.26-2.176-.01-4.267.85-5.806 2.391-1.538 1.54-2.397 3.633-2.384 5.81.02 4.55 3.7 8.236 8.25 8.262 1.75.008 3.456-.55 4.862-1.592l.006-.005 5.745 5.748c.31.325.773.457 1.209.344.435-.113.775-.453.888-.89.113-.435-.02-.898-.345-1.208zm-12.37-4.044c-3.64-.02-6.585-2.969-6.6-6.609-.01-1.742.676-3.415 1.907-4.647 1.23-1.233 2.903-1.922 4.644-1.915 3.64.021 6.584 2.97 6.6 6.61.01 1.74-.677 3.414-1.907 4.647-1.231 1.232-2.903 1.921-4.645 1.914z"
      />
    </svg>
  );
};

export default Search;
