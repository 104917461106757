import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCategories } from "../../../services/faq";
import { AppState, CategoryFAQ } from "../../../types";
import { createRequestState } from "../../../utils";
import { setFaqState } from "../../../store/actions";

export const useCategories = () => {
  const [categories, setCategories] = useState(
    createRequestState<CategoryFAQ[]>()
  );
  const faq = useSelector((state: AppState) => state.faq);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setCategories({ isPending: true, data: null, error: null });
        const categories = await getCategories();
        const categoriesState = {
          isPending: false,
          data: categories,
          error: null,
        };
        dispatch(setFaqState(categoriesState));
        setCategories(categoriesState);
      } catch (error) {
        const categoriesState = { isPending: false, data: null, error };
        dispatch(setFaqState(categoriesState));
        setCategories(categoriesState);
      }
    };
    if (!faq.data) {
      fetchData();
    } else {
      setCategories(faq);
    }
  }, []);
  return categories;
};
