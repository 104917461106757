import React, { ReactElement, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  SearchContainer,
  Title,
  Description,
  BackgroundContainer,
  Background,
  SearchField,
  SearchButton,
} from "./styled";
import SearchIcon from "../../SVG/Search";

interface SearchBlockProps {
  onHandleSearch: (searchValue: string) => void;
  defaultValue: string;
}

const SearchBlock = ({
  onHandleSearch,
  defaultValue,
}: SearchBlockProps): ReactElement => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "search-banner.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 985) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const [buttonColor, setButtonColor] = useState("#161414");
  const [searchValue, setSearchValue] = useState(defaultValue);
  useEffect(() => {
    setSearchValue(defaultValue);
  }, [defaultValue]);
  return (
    <SearchContainer>
      <BackgroundContainer>
        <Background
          fluid={data.background.childImageSharp.fluid}
          loading="eager"
        />
      </BackgroundContainer>
      <Title>FAQ</Title>
      <Description>Du hast Fragen? Hier bist du richtig!</Description>
      <form style={{ position: "relative" }}>
        <SearchField
          id="search"
          name="search"
          type="text"
          placeholder="Suche in allen Kategorien"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onHandleSearch(searchValue);
            }
          }}
        />
        <SearchButton
          onMouseOver={() => {
            setButtonColor("#435de1");
          }}
          onMouseOut={() => {
            setButtonColor("#161414");
          }}
          onClick={() => {
            onHandleSearch(searchValue);
          }}
        >
          <SearchIcon fill={buttonColor} />
        </SearchButton>
      </form>
    </SearchContainer>
  );
};

export default SearchBlock;
