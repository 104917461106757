import request from "./request";
import { queryParams } from "../utils";
import { freshDeskApi } from "../settings";
import { CategoryFAQ } from "../types";

export const getFAQ = (
  url: string,
  params?: Record<string, unknown>
): Promise<any> => {
  const formattedUrl = params
    ? queryParams(`${freshDeskApi}${url}`, params)
    : `${freshDeskApi}${url}`;
  return request(formattedUrl.toString(), {
    method: "get",
    headers: {
      Authorization: "Basic OXdLT3g0WWRtYXRFcmY4ekVXd086WA==",
      "Content-Type": "application/json;charset=utf-8",
    },
  });
};

const getFolders = async (id: number) => {
  try {
    return await getFAQ(`solutions/categories/${id}/folders`);
  } catch (err) {
    return [];
  }
};

const getArticles = async (id: number) => {
  try {
    return await getFAQ(`solutions/folders/${id}/articles`);
  } catch (err) {
    return [];
  }
};

export const getCategories = async () => {
  try {
    const categories = await getFAQ("solutions/categories");
    const { id } = categories[0];
    const folders = await getFolders(id);
    return Promise.all<CategoryFAQ>(
      folders.map(async (folder: CategoryFAQ) => {
        folder.answers = folder.id ? await getArticles(folder.id) : [];
        return folder;
      })
    );
  } catch (err) {
    return [];
  }
};

export const getSearchResult = async (searchRequest: string) => {
  try {
    return await getFAQ("search/solutions", { term: searchRequest });
  } catch (err) {
    return [];
  }
};
